/* source-sans-pro-200 - latin_cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-200.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-200.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-200italic - latin_cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-200italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-200italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-200italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-200italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-300 - latin_cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-300.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-300italic - latin_cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-300italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-300italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-regular - latin_cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-italic - latin_cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-600 - latin_cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-600.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-600italic - latin_cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-600italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-600italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-700 - latin_cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-700.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-700.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-700italic - latin_cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-700italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-700italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-900 - latin_cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-900.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-900.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-900italic - latin_cyrillic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-900italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-900italic.woff') format('woff'), /* Modern Browsers */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../assets/fonts/source-sans-pro-v19-latin_cyrillic-900italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden !important;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body *::-webkit-scrollbar {
  display: none;
}

body * {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#root {
  flex: 1;
  background-color: #fff;
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'GRAD' -25;
}

.material-symbols-outlined.filled {
  font-variation-settings: 'FILL' 1, 'GRAD' -25;
}
